import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { CardSearchResult, CardSearchResultItem } from '@api-clients/crm-api-client';
import { AppConfig } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public clearSearchInputValue$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  public searchResults$: ReplaySubject<{ data: CardSearchResultItem[]; term: string }> = new ReplaySubject<{
    data: CardSearchResultItem[];
    term: string;
  }>();

  constructor(private http: HttpClient) {}

  searchBySlug(term: string): Observable<CardSearchResult> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('term', `${term}`);
    return this.http.get<CardSearchResult>(`${AppConfig.crmApiUrl}/crm-card/search-by-slug`, {
      params: queryParams,
    });
  }
}
